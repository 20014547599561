import { range } from 'd3-array'
import { scaleThreshold } from 'd3-scale'
import { group } from 'd3-array'
import {
  LABELS_SP21,
  LABELS_SP22,
  LABELS_SP71,
  MAP_GRADIENT,
  MAP_THRESHOLDS,
  ANTIGEN_LOOKUP_API
} from './const'

export const getRGBAColorWithOpacity = (color, opacity) => {
  const RGBA_COLORS = {
    brightBlue: `rgba(70, 129, 222, ${opacity})`,
    purple: `rgba(129, 91, 232, ${opacity})`,
    rose: `rgba(213, 95, 154, ${opacity})`,
    aqua: `rgba(82, 180, 173, ${opacity})`,
    darkBlue: `rgba(37, 66, 114, ${opacity})`,
    baseBlue: `rgba(56, 96, 153, ${opacity})`,
    blue4: `rgb(93, 149, 210, ${opacity})`,
    blue5: `rgba(109, 170, 198, ${opacity})`,
    lime: `rgba(155, 188, 46, ${opacity})`,
    medGreen: `rgba(145, 189, 122, ${opacity})`,
    chartreuse: `rgba(185, 181, 52, ${opacity})`,
    yellow2: `rgba(213, 172, 57, ${opacity})`,
    orange: `rgba(217, 147, 46, ${opacity})`
  }
  return RGBA_COLORS[color]
}

//also used for SP3.2 and SP4.1
export const wrangleIg13Data = (data) => {
  data.forEach((d) => {
    d.YEAR_RANGE === '2018-2020' ? (d.year = 2020) : (d.year = d.YEAR)
    d.pathogen = d.DIMENSION2
  })

  const formattedData = data.reduce((acc, d) => {
    if (acc[d.pathogen]) {
      acc[d.pathogen].push({
        ...d
      })
    } else {
      acc[d.pathogen] = [
        {
          ...d
        }
      ]
    }
    return acc
  }, {})

  return formattedData
}
//old
export const wrangle13Data = (data) => {
  const allData = Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = [
        'memberState',
        'iSO3',
        'id',
        'region',
        'regionType',
        'incomeGroup',
        'uNICEFRegion'
      ]
      if (!keysToExclude.includes(key) && value !== null) {
        let year, pathogen
        if (key.includes('Baseline')) {
          const keyArray = key.split('B')
          year = 2020
          pathogen = keyArray[0]
        } else {
          year = +key.slice(-4)
          pathogen = key.slice(0, -4)
        }

        return { year, pathogen, value }
      } else return null
    })
    .filter((item) => item)
  return allData.reduce((acc, d) => {
    if (acc[d.pathogen]) {
      acc[d.pathogen].push({
        year: d.year,
        value: d.value
      })
    } else {
      acc[d.pathogen] = [
        {
          year: d.year,
          value: d.value
        }
      ]
    }
    return acc
  }, {})
}

export const findTrendLine = (data) => {
  //trendline
  // returns slope, intercept and r-square of the line
  function leastSquares(xSeries, ySeries) {
    if (!xSeries.length || !ySeries.length) return null
    var reduceSumFunc = function (prev, cur) {
      return prev + cur
    }

    var xBar = (xSeries.reduce(reduceSumFunc) * 1.0) / xSeries.length
    var yBar = (ySeries.reduce(reduceSumFunc) * 1.0) / ySeries.length

    var ssXX = xSeries
      .map(function (d) {
        return Math.pow(d - xBar, 2)
      })
      .reduce(reduceSumFunc)

    var ssYY = ySeries
      .map(function (d) {
        return Math.pow(d - yBar, 2)
      })
      .reduce(reduceSumFunc)

    var ssXY = xSeries
      .map(function (d, i) {
        return (d - xBar) * (ySeries[i] - yBar)
      })
      .reduce(reduceSumFunc)

    var slope = ssXY / ssXX
    var intercept = yBar - xBar * slope
    var rSquare = Math.pow(ssXY, 2) / (ssXX * ssYY)

    return [slope, intercept, rSquare]
  }

  // extract the x labels for the axis and scale domain
  var xLabels = data?.map((d) => d.year)

  // get the x and y values for least squares
  var xSeries = range(1, xLabels?.length + 1)
  var ySeries = data?.map((d) => parseFloat(d.VALUE_NUM))

  var leastSquaresCoeff = leastSquares(xSeries, ySeries)

  // apply the reults of the least squares regression
  if (data) {
    var x1 = xLabels[0]
    var y1 = leastSquaresCoeff[0] + leastSquaresCoeff[1]
    var x2 = xLabels[xLabels.length - 1]
    var y2 = leastSquaresCoeff[0] * xSeries.length + leastSquaresCoeff[1]
    var trendData = [x1, y1, x2, y2]
  }
  return trendData
}

//test data --
// const dummyYears = ['2020', '2021']
// const dummyData = [
//   { column: 'Achieved', pathogen: 'wPV', value: '192', year: '2020' },
//   { column: 'Endorsed', pathogen: 'wPV', value: '194', year: '2020' },
//   { column: 'ReEstablish', pathogen: 'wPV', value: '0', year: '2020' },
//   { column: 'Achieved', pathogen: 'wPV', value: '5', year: '2021' },
//   { column: 'Endorsed', pathogen: 'wPV', value: '10', year: '2021' },
//   { column: 'ReEstablish', pathogen: 'wPV', value: '15', year: '2021' },
//   { column: 'Achieved', pathogen: 'measles', value: '91', year: '2020' },
//   { column: 'Endorsed', pathogen: 'measles', value: '147', year: '2020' },
//   { column: 'ReEstablish', pathogen: 'measles', value: '10', year: '2020' },
//   { column: 'Achieved', pathogen: 'measles', value: '20', year: '2021' },
//   { column: 'Endorsed', pathogen: 'measles', value: '25', year: '2021' },
//   { column: 'ReEstablish', pathogen: 'measles', value: '30', year: '2021' }
// ]

export const wrangleIg12Data = (data) => {
  data.forEach((d) => {
    const pathogen = d.INDCODE.split('_')[2]
    d.pathogen = pathogen
  })
  const dataMap = group(data, (d) => d.pathogen)

  const groupedByPathogen = Array.from(dataMap.values())

  return groupedByPathogen.map((p) => {
    const yearMap = group(p, (d) => d.YEAR)
    const groupedByYear = Array.from(yearMap.values())

    const yearData = groupedByYear.map((y) => {
      return y.reduce((acc, d) => {
        acc['year'] = d.YEAR
        acc['pathogen'] = d.pathogen
        acc['denominator'] = d.DENOMINATOR
        if (d.DIMENSION2 === 'Achieved') {
          acc['Achieved'] = d.NUMERATOR
        } else if (d.DIMENSION2 === 'Not Achieved') {
          acc['NotAchieved'] = d.NUMERATOR
        } else if (d.DIMENSION2 === 'Re-established') {
          acc['ReEstablished'] = d.NUMERATOR
        }
        return acc
      }, {})
    })
    return yearData.sort((a, b) => (a.year > b.year ? 1 : -1))
  })
}
//old  one
export const wrangle12Data = (data) => {
  const allYears = [],
    allColumns = [],
    allPathogens = []
  const allData = Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = [
        'memberState',
        'iSO3',
        'id',
        'region',
        'regionType',
        'incomeGroup',
        'uNICEFRegion'
      ]
      if (!keysToExclude.includes(key) && value !== null) {
        if (key.startsWith('mNT')) key = key.slice(2) //work around for the 'N' in 'mNT' because otherwise can't split on capital 'N', argh...
        let pathogen, year, column, keyArray
        //console.log('key', key)
        if (key.includes('NotEndorsed')) {
          keyArray = key.split('N')
          column = 'NotEndorsed'
        } else if (key.includes('ReEstablished')) {
          keyArray = key.split('R')
          column = 'ReEstablished'
        } else if (key.includes('NewlyAchieved')) {
          keyArray = key.split('N')
          column = 'NewlyAchieved'
        } else if (key.includes('NotAchieved')) {
          keyArray = key.split('N')
          column = 'NotAchieved'
        } else if (key.includes('Achieved')) {
          keyArray = key.split('A')
          column = 'Achieved'
        }

        year = keyArray[0].slice(-4)
        pathogen = keyArray[0].slice(0, -4)
        allYears.push(year)
        allColumns.push(column)
        allPathogens.push(pathogen === 'T' ? 'mNT' : pathogen)
        return {
          pathogen: pathogen === 'T' ? 'mNT' : pathogen,
          year,
          column,
          value
        }
      } else return null
    })
    .filter((item) => item)

  const years = Array.from(new Set(allYears))
  //const columns = Array.from(new Set(allColumns))
  const pathogens = Array.from(new Set(allPathogens))

  const consolidatedData = pathogens
    .map((pathogen) => {
      return years.map((year) => {
        const pathogenData = allData
          .filter((d) => d.pathogen === pathogen)
          .filter((d) => d.year === year)
          .reduce((acc, d) => {
            acc[d.column] = d.value

            return acc
          }, {})
        return Object.assign(
          {
            year,
            pathogen
          },
          pathogenData
        )
      })
    })
    .reduce((acc, d) => {
      // console.log('d', d)
      if (acc[d[0].pathogen]) {
        acc[d[0].pathogen].push(d)
      } else {
        acc[d[0].pathogen] = d
      }
      return acc
    }, {})

  return consolidatedData
}
// export const wrangle21Data = (data, country = false) => {
//   return Object.entries(data)
//     .map(([key, value]) => {
//       const keysToExclude = [
//         'memberState',
//         'iSO3',
//         'id',
//         'region',
//         'regionType',
//         'incomeGroup',
//         'uNICEFRegion'
//       ]
//       if (!keysToExclude.includes(key) && value !== null) {
//         const year = +key.slice(-4)
//         if (country)
//           return {
//             year,
//             value: value
//           }
//         // this accomodate less than sign in data (bringing it in as string)
//         else return { year, value: +value }
//       } else return null
//     })
//     .filter((item) => item)
// }

//old
export const wrangle22Data = (data) => {
  const allYears = []
  const allData = Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = [
        'memberState',
        'iSO3',
        'id',
        'region',
        'regionType',
        'incomeGroup',
        'uNICEFRegion'
      ]
      if (!keysToExclude.includes(key) && value !== null) {
        const year = +key.slice(-4)
        const vaccine = key.slice(0, -4)
        allYears.push(year)
        return { year, value, vaccine }
      } else return null
    })
    .filter((item) => item)

  const years = Array.from(new Set(allYears))

  return years.map((year) => allData.filter((d) => d.year === year))
}

export const wrangle31Data = (data) => {
  const dataObj = data.reduce((acc, d) => {
    if (acc[ANTIGEN_LOOKUP_API[d.DIMENSION2]]) {
      acc[ANTIGEN_LOOKUP_API[d.DIMENSION2]].push({
        year: d.YEAR,
        value: d.VALUE_NUM
      })
    } else {
      acc[ANTIGEN_LOOKUP_API[d.DIMENSION2]] = [
        {
          year: d.YEAR,
          value: d.VALUE_NUM
        }
      ]
    }
    return acc
  }, {})
  for (const x in dataObj) {
    dataObj[x].sort((a, b) => (a.year < b.year ? -1 : 1))
  }
  return dataObj
}
// export const wrangle31Data = (data) => {
//   const allData = Object.entries(data)
//     .map(([key, value]) => {
//       const keysToExclude = [
//         'memberState',
//         'iSO3',
//         'id',
//         'region',
//         'regionType',
//         'incomeGroup',
//         'uNICEFRegion'
//       ]
//       if (!keysToExclude.includes(key) && value !== null) {
//         const year = +key.slice(-4)
//         const antigen = key.slice(0, -4)
//         return { year, antigen, value }
//       } else return null
//     })
//     .filter((item) => item)

//   return allData.reduce((acc, d) => {
//     if (acc[d.antigen]) {
//       acc[d.antigen].push({
//         year: d.year,
//         value: d.value
//       })
//     } else {
//       acc[d.antigen] = [
//         {
//           year: d.year,
//           value: d.value
//         }
//       ]
//     }
//     return acc
//   }, {})
// }

//old
export const wrangle32Data = (data) => {
  return Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = [
        'memberState',
        'iSO3',
        'id',
        'region',
        'regionType',
        'incomeGroup',
        'uNICEFRegion'
      ]
      if (!keysToExclude.includes(key) && value !== null) {
        const year = +key.slice(-4)
        return { year, value }
      } else return null
    })
    .filter((item) => item)
}

//old
export const wrangleSP12Data = (data) => {
  return Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = [
        'memberState',
        'iSO3',
        'id',
        'region',
        'regionType',
        'incomeGroup',
        'uNICEFRegion'
      ]
      if (!keysToExclude.includes(key) && value !== null) {
        const year = +key.slice(-4)
        return { year, value }
      } else return null
    })
    .filter((item) => item)
}

//old
export const wrangleSP16Data = (data) => {
  const allYears = []

  const allData = Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = [
        'memberState',
        'iSO3',
        'id',
        'region',
        'regionType',
        'incomeGroup',
        'uNICEFRegion'
      ]
      if (!keysToExclude.includes(key) && value !== null) {
        let year, type
        year = key.slice(-4)
        type = key.slice(0, -4)
        allYears.push(year)
        return { year, type, value }
      } else return null
    })
    .filter((item) => item)

  return allData
}

//old
export const wrangleSP32Data = (data) => {
  const allAntigens = []
  const allData = Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = [
        'memberState',
        'iSO3',
        'id',
        'region',
        'regionType',
        'incomeGroup',
        'uNICEFRegion'
      ]
      if (!keysToExclude.includes(key) && value !== null) {
        const year = +key.slice(-4)
        let antigen = key.slice(0, -4)
        allAntigens.push(antigen)
        return { year, antigen, value: value * 100 }
      } else return null
    })
    .filter((item) => item)

  //put 'mean' last in array so it will last in data and on top in chart
  const antigens = Array.from(new Set(allAntigens))

  const consolidatedData = antigens.map((antigen) => {
    return allData.filter((d) => d.antigen === antigen)
  })

  return consolidatedData
}
export const wrangleSp32Data = (data) => {
  return Array.from(group(data, (t) => t.DIMENSION2).values())
    .map((d) => d.sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1)))
    .sort((a, b) => (a[0].DIMENSION2 > b[0].DIMENSION2 ? 1 : -1))
}

//was for overview and country page but wrangling not needed for API data
// export const wrangleSp41Data = (data) => {
//   return Object.entries(data)
//     .map(([key, value]) => {
//       const keysToExclude = [
//         'memberState',
//         'iSO3',
//         'id',
//         'region',
//         'uNICEFRegion'
//       ]
//       if (!keysToExclude.includes(key) && value !== null) {
//         const year = +key.slice(-4)
//         let pathogen = key.slice(0, -4)
//         if (pathogen === 'bOP')
//           return { year, pathogen: 'mean', value: value * 100 }
//         else return null
//       } else return null
//     })
//     .filter((item) => item)
// }
// export const wrangleSp41DataAPI = (data) => {
//   const xx = data
//     .map((d) => {
//       if (d.DIMENSION2 === null) {
//         d.pathogen = 'mean'
//         return d
//       } else return null
//     })
//     .filter((item) => item)
//   return xx

// const dataMap = group(data, (d) => d.DIMENSION2)
// const arr = Array.from(dataMap.values())
// return arr
//   .sort((a, b) => (a[0].pathogen > b[0].pathogen ? 1 : -1))
//   .sort((a, b) => (a[0].pathogen === 'mean' ? 1 : -1))
//}

//this works for SP 2.1, 2.2, 7.1
//this format for region is used only on country page
export const wrangleSpRegionResponseData = (data) => {
  const dataMap = group(data, (d) => d.YEAR)
  const dataByYear = Array.from(dataMap.values()).sort((a, b) =>
    a[0].YEAR > b[0].YEAR ? 1 : -1
  )
  //const baseline = dataByYear[0].find((d) => d.DIMENSION2 === 'YES').VALUE_NUM
  const mostRecentValue = dataByYear[dataByYear.length - 1].find(
    (d) => d.DIMENSION2 === 'YES'
  )
  return {
    value: `${mostRecentValue.NUMERATOR} / ${mostRecentValue.DENOMINATOR}`,
    year: mostRecentValue.YEAR
  }
}

//this format for region is used for charts on indicator pages
export const wrangleSpResponseData = (data) => {
  const dataMap = group(data, (d) => d.YEAR)
  const dataByYear = Array.from(dataMap.values())
  return dataByYear
    .map((dy) => {
      return dy.reduce((acc, d) => {
        if (d.DIMENSION2 === 'YES') acc['yes'] = d.VALUE_NUM
        else if (d.DIMENSION2 === 'NO') acc['no'] = d.VALUE_NUM
        else if (d.DIMENSION2 === 'ND_NR_NORESPONSE') acc['nd'] = d.VALUE_NUM
        acc['year'] = d.YEAR
        return acc
      }, {})
    })
    .sort((a, b) => (a.year > b.year ? 1 : -1))
}
//old
//this works for SP 2.1, 2.2, 7.1
export const wrangleSPResponseData = (data) => {
  const allYears = []

  const allData = Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = [
        'memberState',
        'iSO3',
        'id',
        'region',
        'regionType',
        'incomeGroup',
        'uNICEFRegion'
      ]
      if (!keysToExclude.includes(key) && value !== null) {
        let year, response
        year = key.slice(-4)
        response = key.slice(0, -4)
        allYears.push(year)
        return { year, response, value }
      } else return null
    })
    .filter((item) => item)
  const years = Array.from(new Set(allYears))

  return years.map((year) => {
    const responseData = allData

      .filter((d) => d.year === year)
      .reduce((acc, d) => {
        acc[d.response] = d.value
        acc['year'] = year
        return acc
      }, {})
    return responseData
  })
}
//old
//this works for SP 2.1, 2.2, 7.1 --> had to move into in components in order to translate labels.
export const wrangleSPResponseTableData = (
  regions,
  indicator,
  numCountries
) => {
  const allCategories = []
  const allYears = []
  const allData = Object.entries(regions)
    .map(([key, value]) => {
      const keysToExclude = [
        'memberState',
        'iSO3',
        'id',
        'region',
        'regionType',
        'incomeGroup',
        'uNICEFRegion'
      ]

      if (!keysToExclude.includes(key) && value !== null) {
        const year = key.slice(-4)
        const category = key.slice(0, -4)
        if (category !== 'nRND') {
          allCategories.push(category)
        }
        allYears.push(year)
        return { year, category, value }
      } else return null
    })

    .filter((item) => item)

  const categories = Array.from(new Set(allCategories))
  const years = Array.from(new Set(allYears))

  //borrowing this from chart wrangling so we can total yes and no responses
  const rowsByYear = years.map((year) => {
    const responseData = allData
      .filter((d) => d.year === year)
      .reduce((acc, d) => {
        acc[d.category] = d.value
        acc['year'] = year
        return acc
      }, {})
    return responseData
  })
  const getYesNoTotal = (data, year) => {
    const row = data.find((d) => d.year === year)
    return row.yes + row.no
  }

  const formattedData = categories.map((category) => {
    const categoriesExcluded = ['noData', 'noResponse', 'notRelevant']

    const catData = allData
      .filter((d) => d.category === category)
      .reduce((acc, d) => {
        const val = categoriesExcluded.includes(d.category)
          ? numCountries
          : getYesNoTotal(rowsByYear, d.year)
        acc[d.year] = `${d.value} out of ${val}`
        acc['year'] = d.year
        return acc
      }, {})

    return Object.assign(
      {
        category:
          indicator === '2.1'
            ? LABELS_SP21[category]
            : indicator === '2.2'
            ? LABELS_SP22[category]
            : LABELS_SP71[category]
      },
      catData
    )
  })
  const totals = years.map((y) => {
    const t = getYesNoTotal(rowsByYear, y)
    return {
      category: 'Total with response',
      [y]: `${t} out of ${numCountries}`,
      year: y
    }
  })

  return totals.concat(formattedData)
}
//old
export const wrangleSP51Data = (data) => {
  const allYears = []

  const allData = Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = [
        'memberState',
        'iSO3',
        'id',
        'region',
        'regionType',
        'uNICEFRegion'
      ]
      if (!keysToExclude.includes(key) && value !== null) {
        let year, response
        //only need totals columns here
        if (key.includes('Baseline')) {
          year = 'Baseline'
          response = key.slice(0, -8)
        } else {
          year = key.slice(-4)
          response = key.slice(0, -4)
        }

        allYears.push(year)
        return { year, response, value: +value }
      } else return null
    })
    .filter((item) => item)

  const years = Array.from(new Set(allYears))

  return years.map((year) => {
    const responseData = allData

      .filter((d) => d.year === year)
      .reduce((acc, d) => {
        acc[d.response] = d.value
        acc['year'] = d.year
        return acc
      }, {})
    return responseData
  })
}
//old
//for overview and country page
export const wrangleSP62Data = (data) => {
  const allYears = []
  const allData = Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = ['memberState', 'iSO3', 'id', 'region']

      if (!keysToExclude.includes(key) && value) {
        const years = key.slice(-8)
        const year1 = years.slice(0, 4)
        const year2 = years.slice(-4)
        const yearWithHyphen = `${year1}-${year2}`
        let category
        //gsheets plugin makes key start with lower case, so this identifies the beginning of a key
        if (key.includes('decline')) {
          category = 1
        }
        if (key.includes('stable')) {
          category = 2
        }
        if (key.includes('increase')) {
          category = 3
        }

        allYears.push(yearWithHyphen)
        return { year: yearWithHyphen, category, value }
      } else return null
    })

    .filter((item) => item)

  const years = Array.from(new Set(allYears))

  return years.map((year) => {
    const total = allData
      .filter((d) => d.year === year)
      .reduce((acc, d) => {
        return acc + d.value
      }, 0)

    const categoriesToInclude = [2, 3]
    const subtotal = allData
      .filter(
        (d) => d.year === year && categoriesToInclude.includes(d.category)
      )
      .reduce((acc, d) => {
        return acc + d.value
      }, 0)
    return { year, total, subtotal }
  })
}

export const scaleFromMapValues = (data) => {
  return scaleThreshold().domain(MAP_THRESHOLDS).range(MAP_GRADIENT)
}
